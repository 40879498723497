.Footer-wrapper {
  text-align: center;
  padding-top: 4rem;
  background: rgb(0, 0, 0,0.68);
  backdrop-filter: blur(22px);
  width: 100vw;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  height: 20rem;
  bottom: 0;
  /* border-top: 1px solid #282b4c; */
}

.Footer-logo {
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer-logo img {
  height: 2.65rem;
}

.Footer-social-link-block {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
}
.Footer-link {
  color: #ffffffb5;
  font-size: var(--font-base);
  line-height: 1.85rem;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
}
.Footer-link:hover {
  color: white;
}

.Footer-social-link-block .App-social-link {
  margin: 0 3.2rem;
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  align-items: center;
  justify-content: center;
}

.Footer-social-link-block .App-social-link:hover img {
  filter: brightness(0) invert(1);
}

.Footer-copyright {
  padding: 1.6rem;
}

.Footer-copyright__text {
  font-family: Circular Std;
  font-size: 1.3rem;
  line-height: 1.3rem;
  letter-spacing: -0.41px;
  color: #a0a3c4;
  display: block;
}
.Footer-links {
  padding-bottom: 4rem;
  display: flex;
  justify-content: center;
}
.Footer-links > a:not(:last-child),
.Footer-links > .a:not(:last-child) {
  padding-right: 2rem;
}

@media (max-width: 900px) {
  .Footer-social-link-block .App-social-link {
    margin: 0 1.6rem;
  }
}

@media (max-width: 580px) {
  .Footer-links {
    flex-direction: column;
  }
  .Footer-links > a {
    padding-right: 0;
    margin-bottom: 0.5rem;
    display: block;
  }
  .Footer-social-link-block {
    margin-bottom: 1.4rem;
  }
  .Footer-wrapper {
    padding-top: 2.5rem;
  }
  .home {
    height: 23rem;
  }
  .Footer-links > a:not(:last-child),
  .Footer-links > .a:not(:last-child) {
    padding-right: 0;
  }
  .Footer-social-link-block .App-social-link {
    margin: 0 0.8rem;
  }
}
