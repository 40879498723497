.BuyDFXDLP {
  background-image: url("../../img/background.png");
  min-height: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  background-color: var(--primary);

}

.BuyDFXDLP-container {
  padding: 0 0 3.1rem 0;
  width: 100%;
}


.BuyDFXDLP-container .section-title-content {
  justify-content: flex-start;
}
